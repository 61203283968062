<template lang="pug">
.FSectionOverlay(:style="style")
  .FSectionOverlay__content
    // @slot The section mask content
    slot
</template>

<style lang="stylus">
.FSectionOverlay
  position absolute
  width 100%
  top var(--FSectionOverlay--verticalShift)
  height calc(100% - var(--FSectionOverlay--verticalShift))
  overflow hidden

.FSectionOverlay__content
  height auto
  top calc(0 - var(--FSectionOverlay--verticalShift))
</style>

<script setup lang="ts">
export interface FSectionOverlayProps {
  /**
   * Allow to shift the content vertically with an offset of the container height
   */
  verticalShift?: string | number;
}

const props = withDefaults(defineProps<FSectionOverlayProps>(), {
  verticalShift: '0%',
});

const style = computed(
  (): Style => ({
    '--FSectionOverlay--verticalShift': genSize(props.verticalShift),
  })
);
</script>
